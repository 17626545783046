<template>
  <div class="flex-around home_pop nav_son">
    <div v-for="(item, index) in navList" :key="item.title">
      <div class="title" @click="bindnavpath(item.herf)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      navList: [
        { title: "公司简介", herf: "introduce", target: "" },
        { title: "发展历程", herf: "history", target: "" },
        { title: "企业文化", herf: "culture", target: "" },
        { title: "公司荣誉", herf: "honor", target: "" },
        { title: "合作伙伴", herf: "cooperation", target: "" },
        { title: "联系我们", herf: "contactus", target: "" },
      ],
    };
  },
  methods:{
    bindnavpath(path){
      console.log(path)
      this.$router.push({name:path})
    }
  },
};
</script>

<style lang="scss" scoped>
// .nav_son {
//   margin-top: 5px;
// }
.title {
  color: #333;
  font-size: 14px;
  margin-bottom: 5px;
  width: 94px;
  cursor: pointer;
  text-align: center;
}
.home_pop {
  padding: 10px 15px 15px;
}
.cell {
  cursor: pointer;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
  margin-bottom: 4px;
}
.title:hover {
  color: #006acd;
}
@media screen and (min-width: 992px) and (max-width: 1440px)  {
  .title2{
    font-size: 12px !important;
  }
  .title{
    font-size: 13px !important;
  }
 dl dd{
  font-size: 12px !important;
 } 
}
</style>