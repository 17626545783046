<template>
  <div>
    <div class="page_body_con">
      <div class="margin-tb-26 flex-space-center">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>产品中心</el-breadcrumb-item>
            <!-- <el-breadcrumb-item>{{ currentTxt }}</el-breadcrumb-item> -->
          </el-breadcrumb>
        </div>
        <ul class="flex-space-center product_list">
          <!-- <template v-for="item in productList">
            <select-group
              :options="item"
              @bindchange="bindchange"
            ></select-group>
          </template> -->
          <li
            v-for="item in productList"
            @click="bindchange(item.id)"
            :class="[type_id == item.id ? 'active' : '']"
          >
            {{ item.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="page_body_con moment-row">
      <el-row :gutter="20">
        <el-col :span="6" v-for="(o, index) in list" :key="o.id">
          <div class="animation-fadeInUp" @click="bindProductDetail(o.id)">
            <el-card>
              <div class="item_img">
                <!-- <img
                  src="http://qiniu.ccsc58.com/FtmQCpSp5li9AhGFvaaCMShmDbLF"
                  class="image"
                /> -->
                <img :src="o.pic" class="image" />
                <p class="ta-l">
                  <span class="line10">
                    {{ o.desc }}
                  </span>
                  <!-- {{ o.desc }} -->
                </p>
              </div>

              <div class="bottom">
                <h6 class="title line1">{{ o.title }}</h6>
                <!-- <span class="description"
                  >{{o.desc}}</span
                > -->
              </div>
            </el-card>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="page_body_con pagination ta-c">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[8, 10, 20, 50, 100]"
        :page-size="limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import SelectGroup from "@/components/SelectGroup.vue";
export default {
  components: {
    SelectGroup,
  },
  data() {
    return {
      page: 1,
      limit: 8,
      total: 0,
      productList: [],
      list: [],
      imgsrc: require("@/assets/images/product/10TPU.png"),
      type_id: "",
    };
  },
  watch: {
    $route: {
      handler: function(route) {
        console.log(route);
        if (route.path == "/product/list") {
          this.type_id = route.query.type == undefined ? "" : route.query.type;
          this.page = 1;
          this.getProductList();
        }
      },
      immediate: true,
    },
  },
  activated() {
    // this.getTree();
    // this.getProductList()
    // console.log(this.$store.state.tree)
    this.productList = this.$store.state.tree[0].children;
  },
  methods: {
    bindchange(e) {
      console.log("父", e);
      this.page = 1;
      this.type_id = e;
      this.getProductList();
    },
    getTree() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 1,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.productList = res.data[0].children[0].children;
          // console.log(res.data[0].children)
        }
      });
    },
    getProductList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: this.limit,
          page: this.page,
          type: 1,
          type_id: this.type_id,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.list = res.data.data;
          this.total = res.data.count;
          // console.log(res.data[0].children)
        }
      });
    },
    bindProductDetail(e) {
      this.$router.push({
        path: "/product/productdetail",
        query: {
          //query是个配置项
          id: e,
        },
      });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.limit = val;
      this.getProductList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.page = val;
      this.getProductList();
    },
  },
};
</script>

<style lang="scss" scoped>
.product_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  // align-items: center;
  // margin-top: 20px;
  // margin-right: -15px;
  li {
    height: 42px;
    min-width: 100px;
    background: rgba(255, 255, 255, 0.9);
    color: #333;
    margin: 0px 0px 0px 15px;
    line-height: 42px;
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    padding: 0 15px;
    border-radius: 4px;
    font-weight: bold;
  }
  li:hover {
    font-weight: bold;
    height: 42px;
    background: #006acd;
    color: #fff;
    border-radius: 4px;
  }
  .active {
    height: 42px;
    background: #006acd;
    color: #fff;
    border-radius: 4px;
  }
}
.button {
  padding: 0;
  float: right;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}

.clearfix:after {
  clear: both;
}
.moment-row {
  text-align: center;
  margin-top: 30px;
  padding-bottom: 30px;
  // overflow: hidden;
  // float: right;
  ::v-deep .el-card {
    margin-bottom: 20px;
    padding: 0;
    cursor: pointer;
    .el-card__body {
      padding: 0;
    }
    .item_img {
      width: 100%;
      height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      .image {
        width: 200px;
        height: 200px;
      }
      p {
        display: none;
      }
    }
    .bottom {
      background: #e4e4e4;

      .title {
        height: 36px;
        line-height: 36px;
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 600;
        color: #333333;
        margin: 0 auto;
      }
      .description {
        height: 24px;
        line-height: 24px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 300;
        color: #333333;
        opacity: 1;

        // line-height: 30px;
      }
    }
  }
  ::v-deep .el-card:hover {
    .item_img {
      position: relative;
      p {
        display: flex;
        padding: 0 15px;
        align-items: center;
        position: absolute;
        width: 100%;
        height: 300px;
        background: #006acd;
        opacity: 0.8;
        line-height: 24px;
        color: #fff;
      }
    }
    .bottom {
      background: #999999;
    }
  }

  .more {
    // width: 23px;
    height: 16px;
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 300;
    line-height: 19px;
    color: #4d4d4d;
    opacity: 1;
    margin: 31px 0;
    cursor: pointer;
  }
}
@-webkit-keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(20px);
    -ms-transform: translateY(20px);
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}
// .pagination {
//   margin-bottom: 55px;
// }
</style>
