<template>
  <div class="page">
    <div class="input-contianer">
      <el-input size="medium" placeholder="搜索..." suffix-icon="el-icon-search"></el-input>
    </div>
    <div class="page_body_con favorite">
      <div class="title">最受欢迎的内容</div>
      <div class="favorite-list">
        <div class="favorite-item app_pointer" @click="$router.push('/solution')">
          <img :src="require('@/assets/images/search/1.png')" width="100%" />
        </div>
        <div class="favorite-item app_pointer" @click="$router.push('/location')">
          <img :src="require('@/assets/images/search/2.png')" width="100%" />
        </div>
        <div class="favorite-item app_pointer" @click="$router.push('/moment')">
          <img :src="require('@/assets/images/search/3.png')" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

  data() {
    return {

    }
  },

  activated() {
    window.scrollTo(0, 0);
  },

  methods: {

  },
}
</script>

<style lang="scss" scoped>
.input-contianer {
  padding: 80px 0;

  width: 482.98px;
  left: 0;
  right: 0;
  margin: auto;
}
.title {
  width: 107px;
  height: 21px;
  font-size: 15px;
  font-family: PingFang SC;
  font-weight: 600;
  color: #4d4d4d;
  opacity: 1;
}
.favorite {
  left: 0;
  right: 0;
  margin: 0 auto;
  margin-bottom: 60px;
}
.favorite-list {
  margin-top: 6px;
}
.favorite-item {
  display: inline-block;
  width: 213px;
  margin-right: 12px;
}
</style>