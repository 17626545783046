<template>
  <div class="page">
    <div class="page_header_img">
      <img :src="require('@/assets/images/header/bg_transport.png')" width="100%" />
      <img :src="require('@/assets/images/header/bg_white.png')" width="100%" class="header_bottom" />
    </div>
    <div class="page_body_con">
      <Menu :list="menulist" :activeMenuID="$route.path"></Menu>
      <keep-alive>
        <router-view />
      </keep-alive>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeMenuID: '/solution',
      menulist: [
        {
          id: "/solution",
          name: '仓储',
          children: [
            {
              id: "/solution",
              name: '高标冷库'
            },
            {
              id: "/solution/store",
              name: '多温存储'
            },
            {
              id: "/solution/auto",
              name: '自动化'
            },
            {
              id: "/solution/extra",
              name: '增值服务'
            }
          ]

        },
        {
          id: "/solution/transport",
          name: '运输',
          children: [
            {
              id: "/solution/transport",
              name: '自有车队'
            },
            {
              id: "/solution/transport/delivery",
              name: '多样化配送'
            },
            {
              id: "/solution/transport/management",
              name: 'TMS'
            },
          ]
        },
        {
          id: "/solution/service",
          name: '衍生服务',
          children: [
            {
              id: "/solution/service",
              name: '货代'
            },
            {
              id: "/solution/service/forwarder",
              name: '库购'
            },
            {
              id: "/solution/service/money",
              name: '钱仓'
            },
          ]
        }
      ]
    }
  },
  activated() {
    window.scrollTo(0, 0);
  },
}
</script>

<style lang="scss" scoped>
</style>