<template>
  <div class="serviceForwarder">
    <div class="title">库购</div>
    <div class="row">
      <div class="left">
        <div>世界各地的健康美味</div>
        <div>冒着冷气送到家</div>
      </div>
      <div class="right">
        <p>
          智本库购为您贴心挑选来自世界各地的健康美味。以冷库网络及高效配送为依托，库购平台将全世界的新鲜与美味端上您的餐桌。
        </p>
      </div>
    </div>
    <div class="image">
      <div class="first">
        <img :src="require('@/assets/images/service/3.png')" width="100%" />
      </div>
      <div class="page_body_con flex-space">
        <img :src="require('@/assets/images/service/4.png')" width="326px" />
        <img :src="require('@/assets/images/service/5.png')" width="326px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceForwarder",
};
</script>

<style lang="scss" scoped>
.first {
  width: 100%;
  margin-bottom: 22px;
}
</style>
