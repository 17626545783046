<template>
  <div class="page">
    <div class="page_header_img">
      <Banner :bannerList="bannerList"></Banner>
    </div>
    <div class="page_body_con introducetop">
      <div class="margin-tb-26 flex-space-center">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>关于智本</el-breadcrumb-item>
            <el-breadcrumb-item>{{ currentTxt }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
        <div class="padding-rt-45">
          <Menu :list="menulist" :activeMenuID="$route.path" @CurrentId="CurrentId"></Menu>
        </div>
      </div>
    </div>

    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import PopView from "@/components/PopView.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    PopView,
    Banner,
  },
  data() {
    return {
      bannerList: [
        // { src: require("@/assets/images/header/banner1.png") },
        // { src: require("@/assets/images/header/banner2.png") },
      ],
      menulist: [
        {
          id: "/introduce/index",
          name: "公司简介",
        },
        {
          id: "/introduce/history",
          name: "发展历程",
        },
        {
          id: "/introduce/culture",
          name: "企业文化",
        },
        {
          id: "/introduce/honor",
          name: "公司荣誉",
        },
        {
          id: "/introduce/cooperation",
          name: "合作伙伴",
        },
        {
          id: "/introduce/contactus",
          name: "联系我们",
        },
      ],
      currentTxt: "",
    };
  },
  //   watch: {
  //   $route: {
  //     handler: function (route) {
  //       console.log(route.query.type);

  //       if (route.query.type == 1) {
  //         window.scrollTo(0,1000);
  //         console.log(222);
  //       } else{
  //          window.scrollTo(0,0);
  //       }
  //     },
  //     immediate: true,
  //   },
  // },
  activated() {
    setTimeout(() => {
      this.bannerList = this.$store.state.bannerList.filter((item) => {
        let sonarr = item.type.split("|");
        return sonarr.includes("2");
      });
    }, 500);

    if (this.$route.query.type == 1) {
      window.scrollTo(0, 1000);
    }
    let arr = this.menulist.filter((item, index) => {
      return item.id == this.$route.path;
    });
    this.currentTxt = arr[0].name;
  },

  methods: {
    CurrentId(data) {
      console.log(data);
      this.currentTxt = data;
    },
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          this.bannerList = arr;
          // console.log(res.data[0].children)
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 100px;
}
.introducetop {
  margin-bottom: 10px;
}
</style>
