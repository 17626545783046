<template>
  <div class="page">
    <el-carousel
      :interval="4000"
      @change="changeImg"
      style="width: 100%; margin-top: 1px"
      trigger="click"
      :height="bannerHeight + 'px'"
    >
      <el-carousel-item v-for="(item, index) in bannerList" :key="index">
        <el-image
          :class="className"
          style="width: 100%;height: auto;"
          :src="item.src"
          ref="bannerImg"
          fit="cover"
          @load="imgLoad"
        ></el-image>
      </el-carousel-item>
    </el-carousel>

    <div class="">
      <div class="page_title page_body_con">
        <div class="page_location">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item>当前位置:</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/' }">
              <i class="el-icon-s-home"></i> 首页</el-breadcrumb-item
            >
          </el-breadcrumb>
        </div>
      </div>
      <div class="gray_bg1 company_info">
        <div class="page_body_con page_introduce_info">
          <div class="page_introduce_info_lt">
            <img
              :src="require('@/assets/images/main/company.png')"
              width="94%"
              class="animation-slideInLeft"
            />
          </div>
          <div class="page_introduce_info_rt">
            <div class="page_introducetitle_name page_title_name">
              <h3>公司简介</h3>
              <span>COMPANY PROFILE</span>
            </div>
            <div class="page_introduce_info_rt_desc ta-l">
              <p>
                北京中集智本科技有限公司隶属中国国际海运集装箱（集团）股份有限公司（简称：中集集团，股票代码000039），秉承中集集团在智能制造、自动化及物联网领域领先优势，通过物联网核心技术产品与大数据平台研发，在智慧冷链、智慧农牧领域开展多场景业务应用。主要业务包括智能化工程规划建设、环境监测与智能化控制、农牧品保鲜与资产管理、冷链仓储运输配送全数据监测，为客户提供一体化解决方案。
              </p>
              <p>
                中集智本是国家认定的“双高新”技术企业，通过了 ISO9001质量体系认证、
                ISO14001环境管理体系认证、“双软认证”，具备建筑施工和电子与智能化专业承包资质，是中国采购物流联合会会员企业，商务部《农产品冷链流通监控平台建设规范》参编单位。拥有自主知识产权的产品已达40余款，取得产品相关专利13项，软件著作权22项，先后服务客户累计1万余家，产品销售分布在全国32个省、自治区、直辖市，累计销售设备总量突破55万台。
              </p>
            </div>
            <div class="ta-r">
              <el-button type="primary" @click="bindIntroduceMore">更多介绍+</el-button>
            </div>
          </div>
        </div>
        <div class="flex-space-center page_body_con page_introduce_data">
          <div class="page_introduce_data_box">
            <span class="data_info">40+</span>
            <span class="data_title">产品种类</span>
          </div>
          <div class="page_introduce_data_box">
            <span class="data_info">60+</span>
            <span class="data_title">专利资质</span>
          </div>
          <div class="page_introduce_data_box">
            <span class="data_info">550000+</span>
            <span class="data_title">设备销售总量</span>
          </div>
          <div class="page_introduce_data_box">
            <span class="data_info">10000+</span>
            <span class="data_title">合作客户</span>
          </div>
          <div class="page_introduce_data_box">
            <span class="data_info">300+</span>
            <span class="data_title">合作项目</span>
          </div>
        </div>
      </div>
      <div class="page_product">
        <div class="product_title flex-align-top page_body_con">
          <div class="page_title_name product_title_name ta-l">
            <h3>产品中心</h3>
            <span>PRODUCT CENTER</span>
          </div>
          <ul class="product_list">
            <template v-for="(i, si) in productTypeList">
              <li @click="bindProductList(i)" :class="[si == 0 ? 'active' : '']">
                {{ i.name }}
              </li>
            </template>
          </ul>
        </div>
        <div class="product_content screenshot-slider-wrap">
          <div
            class="product_content_first page_body_con flex"
            @click="bindProductInfo(firstHortInfo)"
          >
            <div class="product_first_img">
              <img :src="firstHortInfo.pic" class="animation-slide-bottom" width="100%" />
            </div>
            <div class="product_content_first_rt">
              <div class="title">{{ firstHortInfo.title }}</div>
              <div class="desc ta-l">
                <p v-html="firstHortInfo.desc"></p>
                <!-- <p>
                  1、参数设置<br />
                  用户可以在“中集在线”平台下发参数设置，包括：采集间隔、上传间隔、报警间隔、报警阈值。
                </p>
                <p>
                  2、检测与报警<br />
                  设备具有短路、接地等保护功能，同时实时检测设备离线、电源断电、光照、土壤及温湿度数据，当检测的数据异常或超出设置的报警阈值范围时，则立即报警。
                </p>
                <p>
                  3、数据查询 设备采用联通/移动4G<br />
                  SIM卡通信将数据上传至服务器，用户可在“中集在线”平台查询设备的实时数据及历史数据，设备采用联通/移动4G
                  SIM卡通信将数据上传至服务器。
                </p> -->
              </div>
            </div>
          </div>
          <div class="product_boxs">
            <el-row class="flex page_body_con">
              <el-col
                :span="6"
                v-for="(o, index) in hortPorductList"
                :key="o.id + o.title"
                class="animation-slide-bottom"
                data-animation-slide-bottom-delay="2s"
                :class="[index == 3 ? '' : 'marginrl15']"
              >
                <div @click="bindProductInfo(o)">
                  <el-card :body-style="{ padding: '0px' }">
                    <div class="product_detail_img">
                      <img :src="o.pic" class="image" />
                    </div>
                    <div class="title">{{ o.title }}</div>
                  </el-card>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
      <div class="page_solution">
        <div class="page_body_con">
          <el-row>
            <el-col :span="4" class="news_lt">
              <div class="ta-l page_title_name">
                <h3>解决方案</h3>
                <span style="text-transform: uppercase">case solution</span>
              </div>
            </el-col>
            <el-col :span="20">
              <ul class="solution_list">
                <template v-for="(i, si) in solutionTypeList">
                  <li @click="bindSolutionList(i.name)">
                    {{ i.name }}
                  </li>
                </template>
              </ul>
            </el-col>
          </el-row>
          <el-row class="flex-space solution_content">
            <el-col
              :span="8"
              v-for="(o, index) in Solutionlist"
              :key="o.id + o.title"
              class="animation-slide-bottom"
              data-animation-slide-bottom-delay="2s"
              :class="[index == 1 ? 'marginrl30' : '']"
            >
              <div @click="bindSolutionInfo(o)" class="solution_item_detail">
                <div class="solution_detail_img">
                  <img :src="o.pic" class="image" />
                </div>
                <div class="footer ta-l">
                  <p class="title">{{ o.title }}</p>
                  <p class="desc line3">{{ o.desc }}</p>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div class="page_news">
        <el-row class="flex-space page_body_con">
          <el-col :span="4" class="news_lt">
            <div class="ta-l page_title_name">
              <h3>新闻中心</h3>
              <span>PRODUCT CENTER</span>
            </div>
            <ul>
              <li @click="bindNewsType(1)" :class="[newsType == 1 ? 'active' : '']">
                企业新闻
              </li>
              <li @click="bindNewsType(2)" :class="[newsType == 2 ? 'active' : '']">
                集团新闻
              </li>
            </ul>
            <img :src="require('@/assets/images/main/news.png')" class="barimg" />
          </el-col>
          <el-col :span="20" class="new_rt">
            <div v-if="newsType == 1">
              <div
                class="news_item flex animation-slide-left"
                v-for="(o, index) in newslist"
                @click="bindNewDetail(o.id)"
              >
                <div>
                  <img :src="o.pic" />
                </div>

                <div class="rt ta-l">
                  <h6 class="litle">
                    {{ o.title }}
                  </h6>
                  <p>
                    {{ o.desc }}
                  </p>
                  <p>发布者：中集智本 {{ o.create_time }}</p>
                </div>
              </div>
            </div>
            <div v-else>
              <div
                class="news_item flex animation-slide-left"
                v-for="(o, index) in newslist"
                @click="bindNewDetail(o.id)"
              >
                <div>
                  <img :src="o.pic" />
                </div>

                <div class="rt ta-l">
                  <h6 class="litle">
                    {{ o.title }}
                  </h6>
                  <p class="line3">
                    {{ o.desc }}
                  </p>
                  <p>发布者：中集智本 {{ o.create_time }}</p>
                </div>
              </div>
            </div>
            <el-button @click="bindMoreNewList">查看更多+</el-button>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      bannerList: [
        // { src: require("@/assets/images/header/banner1.png") },
        // { src: require("@/assets/images/header/banner2.png") },
      ],
      className: "",
      newsType: 1,
      newslist: [],
      hortPorductList: [],
      productTypeList: [],
      firstHortInfo: {},
      solutionTypeList: [{ name: "智慧冷链" }, { name: "智慧养殖" }, { name: "智慧农业" }],
      Solutionlist: [],
      bannerHeight:0,
    };
  },
  // watch: {
  //   bannerList: {
  //     handler(nval, oval) {
  //       console.log(nval, oval); //nval改变后的新数据，oval改变前的旧数据
  //       let arr = [];
  //       nval.forEach((item) => {
  //         let sonarr = item.type.split("|");
  //         if (sonarr.includes("1")) {
  //           console.log(item);
  //           arr.push(item);
  //         }
  //       });
  //       this.curbannerList = arr;
  //       console.log(arr);
  //     },
  //     deep: true, // 深度监听
  //     immediate: true, //立即执行
  //   },
  // },

  created() {
    setTimeout(() => {
      if (this.$store.state.tree) {
        this.bannerList = this.$store.state.bannerList.filter((item) => {
          let sonarr = item.type.split("|");
          return sonarr.includes("1");
        });
      }
      this.productTypeList = this.$store.state.tree[0].children;
    }, 500);
  },
  activated() {
    window.scrollTo(0, 0);
    this.getNewList();
    this.getPortuctList();
    // this.getProduct();
    this.getSolutionList();
    this.className = "lun-img";
    setTimeout(() => {
      this.className = "lun-img-two";
    }, 300);
  },
  methods: {
    bindNewDetail(id) {
      this.$router.push({
        path: "/news/detail",
        query: {
          id,
        },
      });
    },
    bindProductList(o) {
      this.$router.push({
        path: "/product/list",
        query: {
          //query是个配置项
          type: o.id,
        },
      });
    },
    bindProductInfo(o) {
      this.$router.push({
        path: "/product/productdetail",
        query: {
          //query是个配置项
          id: o.id,
        },
      });
    },
    bindSolutionList(o) {
      if (o == "智慧冷链") {
        this.$router.push({
          path: "/solution/coldchain/index",
          query: {
            //query是个配置项
            type: 3,
          },
        });
      } else if (o == "智慧养殖") {
        this.$router.push({
          path: "/solution/foodfresh/index",
          query: {
            //query是个配置项
            type: 4,
          },
        });
      } else {
        this.$router.push({
          path: "/solution/breed/index",
          query: {
            //query是个配置项
            type: 5,
          },
        });
      }
    },
    bindNewsType(e) {
      this.newsType = e;
      this.getNewList();
    },
    getSolutionList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: 3,
          page: 1,
          type: 2,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.Solutionlist = res.data.data;
        }
      });
    },
    getNewList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: 5,
          page: 1,
          type: 3,
          type_id: this.newsType,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.newslist = res.data.data;
        }
      });
    },
    changeImg(e) {
      this.className = "lun-img";
      setTimeout(() => {
        this.className = "lun-img-two";
      }, 300);
    },
    bindIntroduceMore() {
      this.$router.push({
        path: "/introduce/index",
      });
    },
    bindMoreNewList() {
      if (this.newsType == 1) {
        this.$router.push({
          path: "/news/list",
          query: {
            type: 1,
          },
        });
      } else {
        this.$router.push({
          path: "/news/list",
          query: {
            type: 2,
          },
        });
      }
    },
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          this.bannerList = arr;
          this.$store.commit("updatebannerList", arr);
          // console.log(res.data[0].children)
        }
      });
    },
    getPortuctList() {
      this.$http({
        method: "get",
        url: "website_content_list",
        params: {
          limit: 5,
          page: 1,
          type: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          this.hortPorductList = res.data.data.filter((item, index) => {
            return index != 0;
          });
          this.firstHortInfo = res.data.data[0];
        }
      });
    },
    bindSolutionInfo(o) {
      if (o.type_id == 3) {
        this.$router.push({
          path: "/solution/coldchain/detail",
          query: {
            id: o.id,
          },
        });
      } else if (o.type_id == 4) {
        this.$router.push({
          path: "/solution/foodfresh/detail",
          query: {
            id: o.id,
          },
        });
      } else {
        this.$router.push({
          path: "/solution/breed/detail",
          query: {
            id: o.id,
          },
        });
      }
    },
    getProduct() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 0,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          console.log(res.data[0].children[0].children);
          this.productTypeList = res.data[0].children[0].children;
          this.$store.commit("updateTree", res.data);

          // console.log(res.data[0].children)
        }
      });
    },
    imgLoad(){
      this.$nextTick(()=>{
        // console.log(this.$refs.bannerImg[0].);
        this.bannerHeight = this.$refs.bannerImg[0].$el.clientHeight;
        console.log(this.bannerHeight,'this.$refs.bannerImg[0].height--');
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.carousel-map {
  width: 100%;
  height: 580px;
  overflow: hidden;
  .lun-img {
    transform: scale(1.5);
  }
  .lun-img-two {
    transition: all 3s;
    transform: scale(1);
  }
  .el-carousel__item.is-animating {
    transition: all 0.6s;
  }
}
.page_title {
  height: 64px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  position: relative;
  .page_location {
    padding-top: 40px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #999999;
  }
}
.page_introduce_data {
  padding-bottom: 60px;
  margin-top: 60px;
  .page_introduce_data_box {
    width: 34%;
    display: flex;
    flex-direction: column;
    .data_info {
      font-size: 50px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      color: #333;
    }
    .data_title {
      font-size: 24px;
      font-family: Source Han Sans CN;
      font-weight: bold;
      color: #333;
    }
  }
  .page_introduce_data_box:nth-child(3) {
    width: 46%;
  }
}
.company_info {
  background: url("../assets/images/main/companybg.png");
  width: 100%;
  height: 820px;
  background-size: 100% 100%;

  // padding: 30px 0;
  .page_introduce_info {
    display: flex;
    position: relative;
    height: auto;
    .page_introduce_info_lt {
      width: 54%;
      img {
        display: block;
        width: 92%;
        height: 403px;
        margin-top: 100px;
      }
    }
    .page_introduce_info_rt {
      width: 46%;
      padding: 15px 0px 30px 30px;
      font-size: 14px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #333333;
      p {
        line-height: 34px;
        text-indent: 24px;
      }
      .ta-r .el-button {
        background: #006acd;
      }
      .page_introducetitle_name {
        text-align: right;
        margin-top: 30px;
        margin-bottom: 15px;
      }
    }
  }
}

.page_product {
  background: url("../assets/images/main/productBg.png") no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 980px;
  background-size: 100% 100%;
  padding: 30px 0;
  .product_title {
    display: flex;
    justify-content: space-between;
    height: 130px;
    .product_title_name {
      width: 30%;
      h3 {
        color: #ffffff;
      }
      span {
        color: #ffffff;
      }
    }
    .product_list {
      width: 70%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      // align-items: center;
      margin-top: 20px;
      margin-right: -15px;
      li {
        height: 42px;
        width: 140px;
        background: rgba(255, 255, 255, 0.9);
        color: #333;
        margin: 0px 0px 15px 15px;
        line-height: 42px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
      }
      li:hover {
        height: 42px;
        background: #006acd;
        color: #fff;
        border-radius: 4px;
      }
      .active {
        height: 42px;
        background: #006acd;
        color: #fff;
        border-radius: 4px;
      }
    }
  }
  .product_content {
    .product_content_first {
      height: 380px;
      background: #ffffff;
      opacity: 0.9;
      .product_first_img {
        width: 40%;
        height: 446px;
        border-width: 0px 220px 400px 0px;
        border-style: none solid solid;
        border-color: transparent transparent #006bcd;
        margin-top: -55px;
        margin-left: -15px;
        cursor: pointer;
        img {
          display: inline-block;
          width: 360px;
          height: 360px;
          position: absolute;
          margin-top: 65px;
          margin-left: -30px;
        }
      }
      .product_first_img:hover {
        .animation-slide-bottom {
          transform: scale(1.1) !important;
        }
      }
      .product_content_first_rt {
        width: 60%;
        // margin-left: 30px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .title {
          font-size: 30px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #000000;
          margin: 45px 0 30px;
        }
        .desc {
          padding-right: 30px;
          p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #666666;
            line-height: 30px;
          }
        }
      }
    }
    .product_boxs {
      margin: 45px 0;
      .el-row {
        padding: 0;
      }
      .margin15 {
        margin: 0 15px;
      }
      .el-card {
        width: 100%;
        height: auto;
        border: none;
        // background: #ffffff;
        .product_detail_img {
          background: rgba(255, 255, 255, 0.9);
          z-index: 8;
          padding: 30px 0 15px;
          img {
            // display: block;
            height: 240px;
            width: 240px;
          }
          img:hover {
            /* 放大1.3倍 */
            transform: scale(1.2);
          }
        }

        opacity: 0.9;
        cursor: pointer;

        .title {
          height: 48px;
          line-height: 48px;
          background: #006acd;
          opacity: 1;
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #ffffff;
          z-index: 9;
        }
      }
      .el-card:nth-child(1) {
        margin-right: 15px;
      }
    }
  }
}
.page_solution {
  padding: 50px 0 60px;
  margin-bottom: 15px;
  height: 624px;
  background: url("../assets/images/main/solutionBg.png") no-repeat;
  background-size: 100% 100%;
  .solution_list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin-top: 20px;
    margin-right: -15px;
    li {
      width: 140px;
      height: 42px;
      background: rgba(255, 255, 255, 0.9);
      color: #333;
      margin: 0px 0px 15px 15px;
      line-height: 42px;
      font-size: 16px;
      text-align: center;
      cursor: pointer;
    }
    li:hover {
      height: 42px;
      background: #006acd;
      color: #fff;
      border-radius: 4px;
    }
    .active {
      height: 42px;
      background: #006acd;
      color: #fff;
      border-radius: 4px;
    }
  }
  .solution_content {
    margin: 30px 0;
    .solution_item_detail {
      cursor: pointer;
      .solution_detail_img {
        img {
          width: auto;
          max-width: 100%;
          height: 300px;
        }
      }
      .footer {
        // background: #eceded;
        padding: 5px 15px;

        box-sizing: border-box;
        background: rgba(234, 234, 234, 0.65);
        border-radius: 0px 0px 0px 0px;
        opacity: 1;

        .title {
          margin: 0 !important;
          height: 27px;
          font-size: 16px;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #3a3a3a;
          line-height: 29px;
        }
        .desc {
          font-size: 14px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #2e2e2e;
          line-height: 20px;
          height: 60px;
        }
      }
    }
  }
}
.page_news {
  padding: 50px 0 60px;
  margin-bottom: 15px;
  .news_lt {
    margin: 0 0 30px 0;

    ul {
      margin: 30px auto;
      background: #f4f4f4;
      li {
        height: 50px;
        width: 100%;
        background: #f4f4f4;
        color: #333;
        // margin: 0px 0px 15px 15px;
        line-height: 50px;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        border-radius: 4px;
      }
      .active {
        background: #006acd;
        color: #fff;
        border-radius: 4px;
      }
    }
    .barimg {
      width: 100%;
    }
  }

  .new_rt {
    margin-left: 60px;
    .news_item {
      display: flex;
      flex-direction: row;
      margin-bottom: 30px;
      cursor: pointer;
      img {
        display: inline-block;
        width: 200px;
        height: 126px;
      }
      .rt {
        display: flex;
        flex: 1;
        flex-direction: column;
        // margin: 0px 15px;
        margin-left: 20px;
        box-sizing: border-box;
        h6 {
          font-size: 16px;
          font-family: Source Han Sans CN;
          font-weight: bold;
          color: #333333;
          line-height: 32px;
        }
        p {
          font-size: 13px;
          font-family: Source Han Sans CN;
          font-weight: 400;
          color: #666666;
          line-height: 22px;
        }
        p:last-child {
          margin-top: 10px;
        }
      }
    }
    .news_item:hover {
      background: #f2f2f2;
    }
  }
}
.mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100px;
}
.page_body_con {
  text-align: center;
  .cell {
    width: 329px;
  }
}
.desc_moment {
  background-color: #e8ebef;
}
</style>
