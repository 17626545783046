<template>
  <div class="flex-around home_pop">
    <div>
      <div>
        <img :src="require('@/assets/images/headerpop/1.png')" width="140px" />
      </div>
      <div class="title" @click="$router.push('/solution')">仓储</div>
      <div class="cell" @click="$router.push('/solution')">高标冷库</div>
      <div class="cell" @click="$router.push('/solution/store')">多温存储</div>
      <div class="cell" @click="$router.push('/solution/auto')">自动化</div>
      <div class="cell" @click="$router.push('/solution/extra')">增值服务</div>
    </div>
    <div>
      <div>
        <img :src="require('@/assets/images/headerpop/2.png')" width="140px" />
      </div>
      <div class="title" @click="$router.push('/solution/transport')">运输</div>
      <div class="cell" @click="$router.push('/solution/transport')">自有车队</div>
      <div class="cell" @click="$router.push('/solution/transport/delivery')">多样化配送</div>
      <div class="cell" @click="$router.push('/solution/transport/management')">TMS</div>
    </div>
    <div>
      <div>
        <img :src="require('@/assets/images/headerpop/3.png')" width="140px" />
      </div>
      <div class="title" @click="$router.push('/solution/service')">衍生服务</div>
      <div class="cell" @click="$router.push('/solution/service')">货代</div>
      <div class="cell" @click="$router.push('/solution/service/forwarder')">库购</div>
      <div class="cell" @click="$router.push('/solution/service/money')">钱仓</div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.title {
  color: #000000;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
}
.home_pop {
  padding: 20px;
}
.cell {
  cursor: pointer;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #666;
  margin-bottom: 4px;
}
.cell:hover {
  color: #007c00;
}
</style>