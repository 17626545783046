<template>
  <div>
    <div class="page_body_con item_box">
      <el-row class="margin-tb-20 flex">
        <el-col :span="14">
          <baidu-map class="bm-view" :center="infoPosition" :zoom="18" :scroll-wheel-zoom="true">
            <bm-marker
              :position="infoPosition"
              :dragging="false"
              animation="BMAP_ANIMATION_BOUNCE"
              @click="infoWindowOpen"
            >
              <bm-label
                content="北京中集智本科技有限公司"
                :labelStyle="{
                  color: '#444',
                  fontSize: '12px',
                  border: 'none',
                  background: 'none',
                }"
                :offset="{ width: -20, height: -10 }"
              />
              <bm-info-window :show="show" @close="infoWindowClose" @open="infoWindowOpen">
                <div class="page_desc">
                  <p>
                    北京市丰台区总部基地16区11号楼5层
                  </p>
                  <p>电话:400-618-0990</p>
                </div>
              </bm-info-window>
            </bm-marker>
          </baidu-map>
        </el-col>
        <el-col :span="10" class="margin-lt-30">
          <div class="page_title_name">
            <h3>联系我们</h3>
            <span>Contact Us</span>
          </div>
          <div class="page_desc">
            <p><i class="el-icon-location"></i> 北京市丰台区总部基地16区11号楼5层</p>
            <p><i class="el-icon-phone"></i> 电话:400-618-0990</p>
            <p><i class="el-icon-message"></i>zhileng@ccsc58.com</p>
          </div>
          <div class="margin-tb-20 infobox" id="contact">
            <el-form ref="form" :inline="true" :model="form">
              <el-form-item>
                <el-select v-model="form.region" placeholder="请选择">
                  <el-option label="意向合作" value="意向合作"></el-option>
                  <el-option label="咨询问题" value="咨询问题"></el-option>
                  <el-option label="意见反馈" value="意见反馈"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.name" placeholder="您的姓名"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="form.tel" placeholder="您的联系方式"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input
                  type="textarea"
                  :rows="5"
                  placeholder="描述您的问题"
                  v-model="form.desc"
                ></el-input>
              </el-form-item>
              <div class="flex-center">
                <el-button type="primary" @click="onSubmit" :disabled="!issubmit">提交</el-button>
              </div>
            </el-form>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        name: "",
        region: "",
        desc: "",
        tel: "",
      },
      issubmit: true,
      infoPosition: { lng: 116.2987, lat: 39.83214 }, // 信息窗口位置
      show: false, //  信息窗口显示
    };
  },

  methods: {
    infoWindowClose() {
      this.show = false;
    },
    infoWindowOpen() {
      this.show = true;
    },
    onSubmit() {
      if (this.issubmit) {
        let _params = this.$qs.stringify({
          category: this.form.region,
          username: this.form.name,
          contect: this.form.tel,
          content: this.form.desc,
        });
        console.log(_params);
        // return false
        this.$http({
          method: "post",
          url: "website_contectus_add",
          params: _params,
        }).then((res) => {
          if (res.code == 0) {
            this.issubmit = false;
            setTimeout(() => {
              this.issubmit = true;
            }, 5000);
            this.$message({
              message: "提交成功，请等待反馈",
              type: "success",
            });
          } else {
            this.$message({
              message: "提交失败",
              type: "error",
            });
          }
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.progress_con {
  font-size: 0;
  position: relative;
  .space {
    position: absolute;
    width: 100%;
    bottom: -80px;
    left: 0;
  }
}
.bm-view {
  width: 100%;
  height: 520px;
}
.item_box {
  margin-top: 35px;
}
.page_title {
  h6 {
    font-size: 32px;
    font-family: Source Han Sans CN;
    font-weight: 800;
    font-style: italic;
    color: #000000;
    line-height: 36px;
  }
  span {
    font-size: 40px;
    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    font-weight: bold;
    color: #000000;
    line-height: 59px;
    border: none;
  }
}
.page_desc {
  margin-top: 30px;
  p {
    font-size: 14px;
    font-family: Source Han Sans CN-Normal, Source Han Sans CN;
    font-weight: 400;
    color: #746d65;
    line-height: 32px;
    display: flex;
    align-items: center;
    i {
      font-size: 24px;
      margin-right: 5px;
      color: #006acd;
    }
  }
}
.infobox {
  width: 100%;
  .el-input {
    width: 195px;
  }
  .el-select {
    width: 400px;
  }
  .el-textarea {
    width: 400px;
  }
  .el-button {
    margin-right: 176px;
    height: 44px;
    background: #006acd;
    border-radius: 6px 6px 6px 6px;
    opacity: 1;
  }
}
</style>
