<template>
  <div class="transportFleet">
    <div class="title">自有车队</div>
    <div class="row">
      <div class="left">
        <div>智本车队将为您提供更为安全、</div>
        <div>有序、可控、标准化的运维服务</div>
      </div>
      <div class="right">
        <p>
          搭载GPS温控追踪，全面掌握实时讯息。<br />定制车厢搭配高质量冷机，运输全程保证温度。<br />高标仓配一体，
          “库”与“车”的无缝衔接，每个细节都力求“冷链”不断。<br />完善的车队管理制度，司机来源稳定可靠。
        </p>
      </div>
    </div>
    <div class="image">
      <img :src="require('@/assets/images/service/9.png')" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "transportFleet",
};
</script>

<style lang="scss" scoped></style>
