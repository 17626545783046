<template>
  <div class="history_content">
    <!-- <div class="progress_con">
      <img :src="require('@/assets/images/introduce/history/3.png')" width="100%" />
    </div> -->

    <div class=" page_body_con">
      <div class="page_title_name">
        <h3>发展历程</h3>
        <span>Company History</span>
      </div>   
    </div>
   
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.history_content {
  margin-top: 15px;
  position: relative;
  height: 400px;
  background: url('../../assets/images/introduce/history/0.png') no-repeat center 30px;
  background-size: contain;
  .title {
    // position: absolute;
    // left: 10%;
    // top: 10px;
    span {
      font-size: 32px;
      font-family: Source Han Sans CN;
      font-weight: 800;
      font-style: italic;
      color: #000000;
      line-height: 36px;
    }
    h6 {
      font-size: 40px;
      font-family: Source Han Sans CN-Bold, Source Han Sans CN;
      font-weight: bold;
      color: #000000;
      line-height: 59px;
      border: none;
    }
  }
  .box_left {
    img {
      position: absolute;
      bottom: 20px;
      height: 200px;
      width: auto;
    }
  }
}
.progress_con {
  font-size: 0;
  position: relative;
  .space {
    position: absolute;
    width: 100%;
    bottom: -80px;
    left: 0;
  }
}
</style>