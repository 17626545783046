<template>
  <div :id="[ishow ? 'app_nofooter' : 'app']" :style="pageStyle">
    <div class="flex-space-center nav_con nav_white">
      <div class="nav_con_content">
        <div class="nav_icon" style="cursor: pointer" @click="changeSelectedTab('/')">
          CIMC中集智本 <span class="logo_desc">冷暖 智知</span>
        </div>
        <div>
          <div class="flex-end-center">
            <div
              class="butt_pointer"
              :class="$route.path === '/' ? 'hight_light' : ''"
              @click="changeSelectedTab('/')"
            >
              首页
            </div>
            <el-popover placement="bottom" trigger="hover" popper-class="app_cust_pop">
              <el-button
                class="butt_pointer"
                :class="$route.path.startsWith('/introduce') ? 'hight_light' : ''"
                type="text"
                slot="reference"
                @click="changeSelectedTab('/introduce')"
              >
                关于智本
                <i class="el-icon-caret-bottom"></i>
              </el-button>
              <HomePop1></HomePop1>
            </el-popover>
            <el-popover placement="bottom" width="90" trigger="hover" popper-class="app_cust_pop">
              <el-button
                class="butt_pointer"
                :class="$route.path.startsWith('/product') ? 'hight_light' : ''"
                type="text"
                slot="reference"
                @click="changeSelectedTab('/product')"
              >
                产品中心
                <i class="el-icon-caret-bottom"></i>
              </el-button>
              <HomePop2 :productNav="productNav"></HomePop2>
            </el-popover>
            <el-popover placement="bottom" width="100" trigger="hover" popper-class="app_cust_pop2">
              <el-button
                class="butt_pointer"
                :class="$route.path.startsWith('/solution') ? 'hight_light' : ''"
                type="text"
                slot="reference"
                @click="changeSelectedTab('/solution')"
              >
                解决方案
                <i class="el-icon-caret-bottom"></i>
              </el-button>

              <HomePop6 :solutionNav="solutionNav"></HomePop6>
            </el-popover>

            <el-popover placement="bottom" width="90" trigger="hover" popper-class="app_cust_pop">
              <el-button
                class="butt_pointer"
                :class="$route.path.startsWith('/news') ? 'hight_light' : ''"
                type="text"
                slot="reference"
                @click="changeSelectedTab('/news')"
              >
                新闻中心
                <i class="el-icon-caret-bottom"></i>
              </el-button>
              <HomePop7 :newsNav="newsNav"></HomePop7>
            </el-popover>
            <div
              class="butt_pointer"
              :class="$route.path.startsWith('/support') ? 'hight_light' : ''"
              @click="changeSelectedTab('/support')"
            >
              技术支持
            </div>
            <div
              class="butt_pointer"
              :class="$route.path === '/platform' ? 'hight_light' : ''"
              @click="changeSelectedTab('/platform')"
            >
              平台管理
            </div>
            <h4 class="telphone">400-618-0990</h4>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <router-view />
    </keep-alive>
    <div class="footer_con" v-show="!ishow">
      <Footer></Footer>
    </div>
    <div class="theme-bar-setting" v-show="isshopshow">
      <div>
        <a
          href="tencent://message/?uin=812090869&amp;Site=uelike&amp;Menu=yes"
          target="_blank"
          rel="nofollow"
        >
          <img :src="require('@/assets/images/kefu.png')"
        /></a>
      </div>
      <div class="shoppingBox">
        <template v-for="item in shoppingList">
          <a :href="item.link" target="_blank">
            <img :src="item.icon" />
            <span>{{ item.name }}</span>
          </a>
        </template>
      </div>
      <div class="side_bottom" id="tabbarBox">
        <div class="supportTip" v-show="tabbarBoxFixed" @click="bindTop">
          <img :src="sideImgList[3].src" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HomePop1 from "@/components/HomePop1.vue";
import HomePop2 from "@/components/HomePop2.vue";
import HomePop4 from "@/components/HomePop4.vue";
import Footer from "@/components/Footer.vue";
import HomePop6 from "./components/HomePop6.vue";
import HomePop7 from "./components/HomePop7.vue";
export default {
  components: {
    HomePop1,
    HomePop2,
    HomePop4,
    Footer,
    HomePop6,
    HomePop7,
  },

  data() {
    return {
      isTop: true,
      tabbarBoxFixed: false,
      drawerVisible: false,
      sideImgList: [
        { src: require("@/assets/images/side_icon01.png") },
        { src: require("@/assets/images/side_icon02.png") },
        { src: require("@/assets/images/side_icon03.png") },
        { src: require("@/assets/images/side_icon04.png") },
      ],
      shoppingList: [
        {
          name: "淘宝",
          icon: require("@/assets/images/main/shopping/1.jpg"),
          link:
            "https://shop166911921.taobao.com/index.htm?spm=2013.1.w5001-23579535151.7.748f52a6Cxx4gc&scene=taobao_shop",
        },
        {
          name: "京东",
          icon: require("@/assets/images/main/shopping/2.png"),
          link: "https://mall.jd.com/index-11313225.html",
        },
        {
          name: "天猫",
          icon: require("@/assets/images/main/shopping/3.png"),
          link:
            "https://xiandunyb.tmall.com/shop/view_shop.htm?spm=pc_detail.27183998/evo318828b447259.202202.1.5b0e7dd64jQMWw",
        },
        {
          name: "拼多多",
          icon: require("@/assets/images/main/shopping/4.png"),
          link:
            "https://mobile.yangkeduo.com/mall_page.html?mall_id=461712760&msn=6v25l3w5dpsymekhaz2gbqcktq_axbuy&_x_outbound_channel=__outbound_mall__",
        },
        {
          name: "有赞",
          icon: require("@/assets/images/main/shopping/5.png"),
          link:
            "https://shop92005288.m.youzan.com/v2/home/8BZnwKvlhe?kdt_id=91813120&scan=3&from=kdt&shopAutoEnter=1&is_silence_auth=1",
        },
        {
          name: "阿里巴巴",
          icon: require("@/assets/images/main/shopping/6.png"),
          link: "https://shop53a4021723895.1688.com/?spm=0.0.wp_pc_common_topnav_38229151.0",
        },
      ],
      productNav: [],
      solutionNav: [],
      newsNav: [],
    };
  },

  created() {
    document.addEventListener("scroll", this.handleScroll, true);
    this.getTree();
    this.getBannerList();
  },

  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll);
  },

  computed: {
    isNavTransparent() {
      return this.$route.path === "/" && this.isTop;
    },
    ishow() {
      return this.$route.path === "/platform/index";
    },
    isshopshow() {
      if (this.$route.path.includes("product")) {
        return true;
      } else {
        return false;
      }
    },
    pageStyle() {
      if (this.$route.path === "/search") {
        return {
          backgroundColor: "#E7EAEE",
        };
      } else {
        return {};
      }
    },
  },

  methods: {
    bindTop() {
      window.scrollTo(0, 0);
    },
    handleOpenThemeBar() {
      this.drawerVisible = true;
    },
    changeSelectedTab(path) {
      if (this.$route.path === path) {
        return;
      }
      this.$router.push(path);
    },
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          // this.bannerList = arr;
          this.$store.commit("updatebannerList", arr);
          // console.log(res.data[0].children)
        }
      });
    },
    getTree() {
      this.$http({
        method: "get",
        url: "tree",
        params: {
          limit: 1000,
          type: 0,
          forEdit: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          // console.log(res.data[0].children[0].children);
          // this.productTypeList = res.data[0].children[0].children;
          this.$store.commit("updateTree", res.data[0].children);
          this.productNav = res.data[0].children[0].children;
          this.solutionNav = res.data[0].children[1].children;
          this.newsNav = res.data[0].children[2].children;
          // console.log(res.data[0].children)
        }
      });
    },

    handleScroll() {
      if (this.$route.path === "/") {
        if (window.pageYOffset <= 0) {
          this.isTop = true;
        } else {
          this.isTop = false;
        }
      }
      var scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      var offsetTop = document.querySelector("#tabbarBox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
      // console.log(scrollTop, offsetTop);
      if (scrollTop > offsetTop) {
        this.tabbarBoxFixed = true;
      } else {
        this.tabbarBoxFixed = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tips-con {
  background-color: #61dd14;
  color: #fff;
  font-size: 18px;
  padding: 5px;
  border: #e7240a 1px solid;
}
// @import "./assets/css/main.scss";
.theme-bar-setting {
  position: fixed;
  right: 15px;
  z-index: 999;
  width: 180px;
  min-height: 60px;
  text-align: right;
  cursor: pointer;
  // display: flex;
  // align-items: center;

  border-radius: 2px;
  top: calc((100vh - 220px) / 2);
  ::v-deep {
    svg:not(:root).svg-inline--fa {
      display: block;
      margin-right: auto;
      margin-left: auto;
    }

    .svg-icon {
      display: block;
      margin-right: auto;
      margin-left: auto;
      font-size: 20px;
    }
  }
  .side_top {
    .supportTip {
      background: rgb(0, 0, 34);
      padding-left: 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 60px;
      margin-bottom: 2px;
      text-align: center;
      .tipcontent a {
        padding: 10px 0;
        color: #fff !important;
      }
    }
  }
  .shoppingBox {
    // column-count: 3; //多列的列数
    // column-gap: 0; //列间距
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    padding: 0 10px;
    a {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 33%;
      margin-bottom: 5px;
      img {
        width: 36px;
        height: 36px;
      }
      span {
        font-size: 12px;
        font-family: Source Han Sans CN-Regular, Source Han Sans CN;
        font-weight: 400;
        color: #161616;
      }
    }
  }
  .side_bottom {
    margin-top: 30px;
    display: flex;
    justify-content: flex-end;
    margin-right: 15px;
    .supportTip {
      background: #1e5cb3;
      padding-left: 0px;
      margin-left: 20px;
      width: 46px;
      height: 46px;
      border-radius: 23px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 2px;
      .supportTip {
        text-align: center;
      }
      .tipcontent a {
        color: #fff !important;
        font-weight: 700;
      }
    }
  }

  // 修改下面的小三角，属性名根据组件的placement位置做相应修改
}
//最外层div,修改背景色，边框
.el-popover.monitor-yt-popover {
  background-color: #090d29;
  border-color: #090d29;
  margin-left: 30px;
  text-align: center;
  height: 52.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -10px !important;
  a {
    color: #fff;
  }
  // margin-right: 60px;
}

//修改title颜色
.monitor-yt-popover .el-popover__title {
  color: white;
  text-align: center;
}
.el-popover.codewrap {
  width: 80px;
  height: 80px;
  text-align: center;
  border: none;
  background: none;
  box-shadow: none;
  img {
    margin-right: -60px;
  }
}
.el-popover.qqwrap {
  background-color: #090d29;
  border-color: #090d29;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 52.5px;
  height: 52.5px;
  margin-right: -10px !important;
  a {
    color: #fff;
  }
  // margin-right: 60px;
}
.el-popover,
.el-time-panel {
  background: none;
  box-shadow: none;
}
.el-popper[x-placement^="left"] .popper__arrow::after {
  border: none;
}
//修改title颜色
.qqwrap .el-popover__title {
  color: white;
  text-align: center;
}
.el-popper .popper__arrow {
  border: none !important;
}
#app {
  position: relative;
  min-height: 100vh;
  padding-bottom: 258px;
  .footer_con {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
#app_nofooter {
  position: relative;
  height: 100vh;
  overflow: hidden;
  // padding-bottom: 258px;
  .footer_con {
    display: none;
  }
}
.nav_con {
  width: 100%;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 999;
  min-width: 1000px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 0;
  .nav_con_content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .el-button {
      padding: 0;
      margin-top: 3px;
    }
  }
  .flex-end-center {
    height: 80px;
  }
  .nav_icon {
    font-size: 30px;
    font-family: Source Han Sans CN;
    font-weight: bold;
    color: #006acd;
    margin-right: 100px;
  }
  .logo_desc {
    display: inline-block;
    width: 39px;
    height: 28px;
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #006acd;
    line-height: 14px;
    word-break: keep-all;
    padding: 0 0px 0 10px;
    border-left: 2px solid #006acd;
    margin-top: 3px;
  }
  .tips_con {
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: 600;
    margin-bottom: 16px;

    .mobile {
      width: 15px;
      height: 12px;
      background-size: 100% 100%;
    }
  }
  .butt_pointer {
    cursor: pointer;
    margin: 0 20px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 600;
    // padding-bottom: 4px;
    border-radius: 0;
  }
  .telphone {
    font-size: 32px;
    font-family: zcoolwenyiti;
    font-weight: 400;
    color: #333333;
    height: 80px;
    line-height: 80px;
    display: block;
    // margin: 0 60px 0 30px;
    margin-left: 60px;
  }
}

.nav_trans {
  background-image: url("~@assets/images/header/bg_header.png");
  background-size: 100% 100%;
  // .nav_icon {
  //   background-image: url("~@assets/images/logo-o.png");
  // }
  .mobile {
    background-image: url("~@assets/images/icon_mobile-o.png");
  }
  .butt_pointer {
    color: #fff;
  }
  .tips_con {
    color: #fff;
  }
}
.nav_white {
  background-color: #fff;
  // .nav_icon {
  //   background-image: url("~@assets/images/logo.png");
  // }
  .butt_pointer {
    color: #1a1a1a;
  }
  .tips_con {
    color: #808080;
  }
  .mobile {
    background-image: url("~@assets/images/icon_mobile.png");
  }
}
@media screen and (min-width: 992px) and (max-width: 1440px)  {
 .nav_icon {
    margin-right: 40px !important;
    // font-size: 13px !important;
 }
 .butt_pointer{
  font-size: 13px !important;
 }
 .telphone{
  font-size: 25px !important;
 }
 
}
</style>

