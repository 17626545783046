import Vue from "vue"
import Vuex from "vuex"
import persistedState from 'vuex-persistedstate'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    bannerList:[],
    tree:[]
  },
  mutations: {
    updatebannerList(state,data){
      state.bannerList = data
    },
    updateTree(state,data){
      state.tree = data
    }
  },
  actions: {},
  modules: {},
  plugins: [persistedState({
    storage: window.sessionStorage,
    key: 'vuex',
    reducer(val) {
      // console.log(val)
      return {
        // 只储存state中的includes的leftList数据【这里多了一层includes，是因为本项目原因，大家可以打印一下val根据自己项目情况来赋值】
        bannerList: val.bannerList,
        tree: val.tree

      }
    }
  })
]
})
