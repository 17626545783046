<template>
  <div class="page_body_con">
    <div class="page_title_name">
      <h3>公司简介</h3>
      <span>COMPANGY PROFILE</span>
    </div>
    <el-row class="oneline">
      <el-col :span="24" class="ta-c">
        <!-- <div class="greyBg padding-30 margin-rt-45">
          <p>
            北京中集智本科技有限公司，是“中集集团”旗下一家提供智能冷链监控设备解决方案的自主创新型科技企业。致力于医药、食品、养殖、农牧业的实际需求，以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。
            公司拥有资深研发团队和专业管理人才，获得多项科技进步奖项，所有软硬件产品都自主研发，拥有自主知识产权，温控设备相关专利5项，软件著作权12项，自主研发了基于区块链技术应用的云信息平台，帮助客户实现数据的可视可追溯。是国内首创把LAIOT技术应用到冷链行业的践行者，通过云信息平台、TMS管理系统、LAIOT流动资产管理系统等为行业客户量身定制解决方案的服务型企业。
            两大自有品牌“鲜盾”和“药盾”，20余款产品先后服务客户累计2100余家，产品销售分布在全国20多个省、市、自治区。
            两大自有品牌“鲜盾”和“药盾”，20余款产品先后服务客户累计2100余家。
          </p>
        </div> -->
        <img src="../../assets/images/introduce/index/1.png" />
      </el-col>
    </el-row>
    <!-- <el-row class="bottom">
      <el-col :span="10">
        <img src="../../assets/images/main/company.png" />
      </el-col>
      <el-col :span="14">
        <div class="greyBg margin-rt-45 padding-lr-30">
          <p>
            北京中集智本科技有限公司，是“中集集团”旗下一家提供智能冷链监控设备解决方案的自主创新型科技企业。致力于医药、食品、养殖、农牧业的实际需求，以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。
            北京中集智本科技有限公司，是“中集集团”旗下一家提供智能冷链监控设备解决方案的自主创新型科技企业。致力于医药、食品、养殖、农牧业的实际需求，以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。以国家GSP规范为操作标准，从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。从冷链全过程温控、数据的采集和存储等环节为行业客户提供安全、专业、高效、信息化的冷链一体化云平台服务。
          </p>
        </div>
      </el-col>
    </el-row> -->
  </div>
</template>
<script>
export default {};
</script>

<style lang="scss" scoped>
.oneline {
  .title {
  }
}
.bottom {
  display: flex;
  flex-direction: row;

  img {
    margin-top: 10px;
    display: inline-block;
    width: 100%;
    height: 340px;
  }
}
.greyBg {
  background: #f8f8f8;

  p {
    line-height: 28px;
    font-size: 14px;
  }
  padding-bottom: 15px;
}
</style>
