<template>
  <div class="serviceMoney">
    <div class="title">钱仓</div>
    <div class="row">
      <div class="left">
        <div>我们是您的可靠“钱仓”</div>
      </div>
      <div class="right">
        <p>
          我们能够为广大客户提供稳健的资金支持。依托全国联网的高标冷库集群，智本提供贸易监管、贸易代采及贸易代理服务。
        </p>
      </div>
    </div>
    <div class="image">
      <img :src="require('@/assets/images/service/2.png')" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceMoney",
};
</script>

<style lang="scss" scoped></style>
