<template>
  <div class="flex-align-center">
    <div v-for="menu in list" :key="menu.id" class="con ta-c">
      <div>
        <el-button
          v-if="!menu.children"
          class="normal"
          :class="isActive(menu) ? 'normal_selected' : ''"
          type="text"
          @click="menuOpen(menu)"
          >{{ menu.name }}</el-button
        >
        <div v-else>
          <el-popover
            placement="bottom"
            width="90"
            trigger="hover"
            popper-class="app_cust_pop"
          >
            <el-button
              class="normal"
              :class="isActive(menu) ? 'normal_selected' : ''"
              type="text"
              slot="reference"
            >
              {{ menu.name }}
              <i class="el-icon-caret-bottom"></i>
            </el-button>
            <div v-for="subMenu in menu.children" :key="subMenu.id">
              <div class="sub_normal flex-center" @click="menuOpen(subMenu)">
                {{ subMenu.name }}
              </div>
            </div>
          </el-popover>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["list", "activeMenuID"],

  data() {
    return {
      tag: {
        menu: "1",
      },
    };
  },
  computed: {
    menu(newVal, oldVal) {
      console.log(newVal, oldVal);
    },
  },
  methods: {
    menuOpen(menu) {
      this.$router.push(menu.id);
      this.$emit("update:activeMenuID", menu.id);
      this.$emit("CurrentId", menu.name);
      this.tag.menu = menu.id;
    },

    isActive(menu) {
      if (this.activeMenuID === menu.id) {
        return true;
      }
      if (menu.children) {
        return menu.children.find((_subMenu) => {
          return _subMenu.id === this.activeMenuID;
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.con {
  width: 100px;
  // padding: 5px 15px;
  height: 42px;
  line-height: 42px;
  background: #f4f4f4;
  border-radius: 0px 0px 0px 0px;
  opacity: 1;
  margin-left: 15px;
  border-radius: 4px;
  // margin: 5px 0 5px 15px;
  .normal {
    font-size: 16px;

    font-weight: bold;
    color: #333;
    // line-height: 21px;
  }

  .normal_selected {
    width: 100px;
    background: #006acd;
    color: #fff;
    // border-bottom: 2px solid #006acd;
  }
}
</style>

<style lang="scss">
.app_cust_pop {
  .sub_normal {
    width: 100%;
    height: 40px;
    cursor: pointer;
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
  .sub_normal:hover {
    color: #006acd;
    background-color: #fff;
  }
  .normal {
    font-size: 11px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #000000;
    margin-right: 30px;
    cursor: pointer;
    padding-bottom: 4px;
    border-bottom: 2px solid #fff;
    border-radius: 0;
  }

  .normal_selected {
    color: #006acd;
    border-bottom: 2px solid #006acd;
  }
}
</style>