<template>
  <div class="solution_page">
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  activated() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lang="scss" scoped>
</style>