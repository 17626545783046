<template>
  <div class="serviceBuy">
    <div class="title">货代</div>
    <div class="row">
      <div class="left">
        <div>欢迎您的到来</div>
        <div>守护您的启航</div>
      </div>
      <div class="right">
        <p>
          智本提供贸易相关的广泛衍生服务，进出口报关/清关、拖柜、标签合规咨询、国际运输（海运/空运/多式联运）、海外专供等。我们欢迎您的到来，守护您的启航。
        </p>
      </div>
    </div>
    <div class="image">
      <img :src="require('@/assets/images/service/6.png')" width="100%" />
    </div>
  </div>
</template>

<script>
export default {
  name: "serviceBuy",
};
</script>

<style lang="scss" scoped></style>
