<template>
  <div
    class="page"
    :class="CurRouter == '/product/list' ? 'white_bg' : 'grey_bg'"
  >
    <div class="page_header_img">
      <Banner :bannerList="bannerList"></Banner>
    </div>
    <div>{{ $router.path }}</div>
    <keep-alive>
      <router-view />
    </keep-alive>
  </div>
</template>

<script>
import PopView from "@/components/PopView.vue";
import Banner from "@/components/Banner.vue";
export default {
  components: {
    PopView,
    Banner,
  },

  data() {
    return {
      bannerList: [
        // { src: require("@/assets/images/header/banner1.png") },
        // { src: require("@/assets/images/header/banner2.png") },
      ],
      menulist: [
        {
          id: "/product/list",
          name: "产品中心",
        },
        {
          id: "/product/detail",
          name: "产品详情",
        },
      ],
      currentTxt: "",
      value: "",
      CurRouter: "",
    };
  },
  activated() {
    console.log(this.$router);
    window.scrollTo(0, 0);
    // this.getBannerList();
    setTimeout(()=>{
      this.bannerList = this.$store.state.bannerList.filter((item) => {
      let sonarr = item.type.split("|");
      return sonarr.includes("3");
    });
    },500)
    
  },
  methods: {
    getBannerList() {
      this.$http({
        method: "get",
        url: "banner_list",
        params: {
          limit: 10,
          page: 1,
        },
      }).then((res) => {
        console.log(res);
        if (res.code == 0) {
          let bannerList = res.data.data;
          let arr = [];
          bannerList.forEach((element) => {
            element.src = element.pic;
            arr.push(element);
          });
          this.bannerList = arr;
          // console.log(res.data[0].children)
        }
      });
    },
    CurrentId(data) {
      console.log(data);
      this.currentTxt = data;
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      //  console.log(document.querySelector("#tabbarBox"))
      if (document.querySelector("#tabbarBox") != null) {
        var offsetTop = document.querySelector("#tabbarBox").offsetTop; // 获取所需元素到文档顶部的距离，然后判断滚动条位置让该元素是否固定定位
        console.log(scrollTop, offsetTop);
        if (scrollTop > offsetTop) {
          this.tabbarBoxFixed = true;
        } else {
          this.tabbarBoxFixed = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page {
  padding-bottom: 80px;
  background: #f5f5f5;
}
.el-select {
  width: 160px;
  margin-left: 15px;
}
</style>